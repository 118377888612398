import React from "react"
import { Fade } from "react-awesome-reveal"
import styled from "styled-components"
import roomsImage1 from "../assets/images/04_ROOMS_carousel/rooms_1.jpg"
import roomsImage2 from "../assets/images/04_ROOMS_carousel/rooms_2.jpg"
import roomsImage3 from "../assets/images/04_ROOMS_carousel/rooms_3.jpg"
import roomsImage4 from "../assets/images/04_ROOMS_carousel/rooms_4.jpg"
import roomsImage5 from "../assets/images/04_ROOMS_carousel/rooms_5.jpg"
import SEO from "../components/SEO"

import { useKeenSlider } from "keen-slider/react"
import "keen-slider/keen-slider.min.css"
import "../styles/sliderStyler.css"

const PageStyles = styled.div`
  /* scroll-snap-type: y mandatory;
  overflow-y: scroll; */
  height: calc(100vh - 150px);
`

const ImageStyles = styled.div`
  /* scroll-snap-align: start; */
  background-size: cover;
  height: calc(100vh - 150px);
`

const BodyStyles = styled.div`
  /* scroll-snap-align: start; */
  display: flex;
  min-height: calc(100vh - 150px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 130px;

  @media (max-width: 900px) {
    padding-left: 90px;
    padding-right: 40px;
    padding-bottom: 120px;
    padding-top: 45px;
    min-height: 0;
  }

  h1 {
    margin-left: -65px;
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 20px;
  }

  article {
    columns: 2;
    column-width: 330px;
    column-gap: 30px;
  }
`

function RoomsPage() {
  const [pause, setPause] = React.useState(false)
  const timer = React.useRef()
  const [sliderRef, slider] = useKeenSlider({
    loop: true,
    duration: 1000,
    dragStart: () => {
      setPause(true)
    },
    dragEnd: () => {
      setPause(false)
    },
  })

  React.useEffect(() => {
    sliderRef.current.addEventListener("mouseover", () => {
      setPause(true)
    })
    sliderRef.current.addEventListener("mouseout", () => {
      setPause(false)
    })
  }, [sliderRef])

  React.useEffect(() => {
    timer.current = setInterval(() => {
      if (!pause && slider) {
        slider.next()
      }
    }, 2000)
    return () => {
      clearInterval(timer.current)
    }
  }, [pause, slider])

  return (
    <>
      <SEO title="Rooms" image={roomsImage1} />
      <Fade triggerOnce>
        <PageStyles>
          <div ref={sliderRef} className="keen-slider">
            <div
              className="keen-slider__slide number-slide1"
              style={{
                backgroundImage: `url(${roomsImage1})`,
              }}
            ></div>
            <div
              className="keen-slider__slide number-slide2"
              style={{ backgroundImage: `url(${roomsImage2})` }}
            ></div>
            <div
              className="keen-slider__slide number-slide3"
              style={{ backgroundImage: `url(${roomsImage3})` }}
            ></div>
            <div
              className="keen-slider__slide number-slide4"
              style={{ backgroundImage: `url(${roomsImage4})` }}
            ></div>
            <div
              className="keen-slider__slide number-slide5"
              style={{ backgroundImage: `url(${roomsImage5})` }}
            ></div>
          </div>
          <BodyStyles>
            <div>
              <h1>ROOMS</h1>

              <article>
                <p>
                  Island living is all about being outdoors, enjoying the
                  beautiful beaches, sunset drinks and dancing in the sand. We
                  also know that you need to have a peaceful place to relax,
                  read a book, do some work or just spend some down time to
                  recharge before you head out again.
                </p>
                <p>
                  Whether you’re staying in one of our suite rooms or one of our
                  private hostel studio rooms, we trust that you will have a
                  comfortable and relaxing stay with us.
                </p>
                <p>
                  Choose between double suites with king beds, AC, en-suite
                  bathrooms and ocean or jungle view balconies, or private
                  hostel studios, perfect for a solo travellor, with single bed,
                  open air garden, and shared bathrooms.
                </p>
              </article>
            </div>
          </BodyStyles>
        </PageStyles>
      </Fade>
    </>
  )
}

export default RoomsPage
